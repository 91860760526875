import { fetchAuthSession } from "aws-amplify/auth";

const CreateItem = async (item: string, body: any) => {
  let session = await fetchAuthSession();
  console.log(process.env.REACT_APP_API_URL + "/api/v1/" + item)
  const reponse = await fetch(process.env.REACT_APP_API_URL + "/api/v1/" + item, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.tokens?.idToken}`,
    },
    body: JSON.stringify(body),
  });
  if (reponse.ok) {
    return reponse.json();
  }
  else {
    throw new Error("Failed to create item");
  }
};
export default CreateItem;
