import { Sensor } from "../models/Sensor";

type SensorsCardProps = {
  sensors: Sensor[];
};
const SensorsCard: React.FC<SensorsCardProps> = ({ sensors }) => {
  return (
    <div className="flex flex-col  w-full p-6 bg-white border border-gray-200  mr-2 mb-2">
      <p className="font-normal text-gray-700 dark:text-gray-400">Sensors</p>
      <div className="flex flex-row space-between items-center">
      {sensors.map((sensor) => (
        <span
          key={sensor.ID}
          className="bg-blue-100 flex-none text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
          >
            
          {sensor.name}
        </span>

      ))}
      </div>
    </div>
  );
};

export default SensorsCard;
