import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type CardProps = {
  projectAttribute: any;
  faIcon: IconProp;
  text: string;
};

const Card: React.FC<CardProps> = ({ projectAttribute, faIcon, text }) => {
  return (
    <div
      className="mr-3 flex flex-row space-between w-full items-center min-w-56 max-w-sm p-6 bg-white border border-gray-200 "
    >
      <FontAwesomeIcon
        icon={faIcon}
        className="mr-2 text-gray-900"
        size="3x"
        color={"#334155"}
      />
      <div className="block ml-2">
        <p className="font-normal text-gray-700 dark:text-gray-400">{text}</p>
        <h5 className="text-3xl font-bold tracking-tight text-gray-900">
          {projectAttribute}
        </h5>
      </div>
    </div>
  );
};

export default Card;
