import { Project } from "../models/Project";
import { Client } from "../models/Client";
import { TableButton } from "../components/TableButton";
import { TableHeader } from "../components/TableHeader";
import { TableRow } from "../components/TableRow";
const ProjectTableFull = (props: any) => {
    return ( 
        <table className="table-auto w-full">
                <thead>
                    <tr>
                    <TableHeader>Project Code</TableHeader>
                    <TableHeader>Project Title</TableHeader>
                    <TableHeader>Project Type</TableHeader>
                    <TableHeader>Business Unit</TableHeader>
                    <TableHeader>Client</TableHeader>
                    <TableHeader>Latitude</TableHeader>
                    <TableHeader>Longitude</TableHeader>
                    <TableHeader>Location</TableHeader>
                    <TableHeader>Vessel</TableHeader>
                    <TableHeader>Sensors</TableHeader>
                    <TableHeader>Size</TableHeader>
                    <TableHeader>Year</TableHeader>
                    <TableHeader>Contract</TableHeader>
                    <TableHeader>Sharepoint</TableHeader>
                    <TableHeader>Last Updated</TableHeader>
                    <TableHeader></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {props.projectList &&
                    props.projectList.map((project: Project) => (
                        <tr
                        className="hover:bg-rovco-gray text-center"
                        key={project.ID}
                        >
                        <TableRow>{project.code}</TableRow>
                        <TableRow>{project.title}</TableRow>
                        <TableRow>{project.type}</TableRow>
                        <TableRow>{project.businessUnit}</TableRow>
                        <TableRow>{project.clients.map((el : Client) => el.name)}</TableRow>
                        <TableRow>{project.latitude}</TableRow>
                        <TableRow>{project.longitude}</TableRow>
                        <TableRow>{project.location}</TableRow>
                        <TableRow>{project.vessel}</TableRow>
                        <TableRow>
                            {project.sensors.map((el : any) => (
                            <span
                                key={el.ID}
                                className="my-1 mv-1 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10"
                            >
                                {el.name}
                            </span>
                            ))}
                        </TableRow>
                        <TableRow>{project.size}GB</TableRow>
                        <TableRow>{project.year}</TableRow>
                        <TableRow>
                            <a href={project.contractLink}>Contract</a>
                        </TableRow>
                        <TableRow>
                            <a href={project.folder}>Sharepoint</a>
                        </TableRow>
                        <TableRow>{project.updatedAt}</TableRow>
                        <TableRow>
                            <TableButton className="my-1" projectId={project.ID}>
                            View
                            </TableButton>
                        </TableRow>
                        </tr>
                    ))}
                </tbody>
                </table>
    )
};
export { ProjectTableFull };


