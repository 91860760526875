import { fetchAuthSession } from "aws-amplify/auth";
import { Client } from "../models/Client";
import Select from "react-select";
import { Sensor } from "../models/Sensor";
import { Ipr } from "../models/IntellectualPropertyRight";
import { Nas } from "../models/Nas";
import { Tape } from "../models/Tape";
import { Project } from "../models/Project";
import { useState } from "react";
import FormItems from "../components/FormItems";
import CreateItem from "../utils/Create";
import { FailedToast, SuccessToast } from "../components/Toast";
import NewProjectSelect from "../components/NewProjectSelect";
import CreatableSelect from "react-select/creatable";
import CreateTapeModal from "../components/CreateTapeModal";
type AmplifyAuthSession = Awaited<ReturnType<typeof fetchAuthSession>>;

interface Option {
  readonly label: string | undefined;
  readonly value: number;
}

const NewProjectModal = ({
  setShowModal,
  clientList,
  sensorList,
  ipRightsList,
  nasList,
  tapeList,
  session,
  projectList,
  setClientList,
  setSensorList,
  setNasList,
  setTapeList,
  setProjectList,
}: {
  setShowModal: (show: boolean) => void;
  clientList: Client[] | undefined;
  sensorList: Sensor[] | undefined;
  ipRightsList: Ipr[] | undefined;
  nasList: Nas[] | undefined;
  tapeList: Tape[] | undefined;
  session: AmplifyAuthSession | null;
  projectList: Project[] | undefined
  setClientList: (client: Client[]) => void;
  setSensorList: (sensor: Sensor[]) => void;
  setIpRightsList: (ipRights: Ipr[]) => void;
  setNasList: (nas: Nas[]) => void;
  setTapeList: (tape: Tape[]) => void;
  setProjectList: (project: Project[]) => void;
}) => {
  const [selectedSensorList, setSelectedSensorList] = useState<Sensor[]>();
  const [selectedIPList, setSelectedIPList] = useState<Ipr[]>();
  const [selectedNasList, setSelectedNasList] = useState<Nas[]>();
  const [selectedTapeList, setSelectedTapeList] = useState<Tape[]>();
  const [selectedEndClientList, setSelectedEndClientList] =
    useState<Client[]>();
  const [selectedClientList, setSelectedClientList] = useState<Client[]>();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailedToast, setShowFailedToast] = useState(false);
  const [showTapeModal, setShowTapeModal] = useState(false);
  const [tapeOptions, setTapeOptions] = useState<Option[]>([]);
  const [tapeCode, setTapeCode] = useState<string>("");

  const postProject = (e: any, session: any) => {
    e.preventDefault();
    const form = document.getElementById("newProjectForm") as HTMLFormElement;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
    const project: Project = {
      sensors: selectedSensorList || [],
      intellectualPropertyRights: selectedIPList || [],
      clients: selectedClientList || [],
      endClients: selectedEndClientList || [],
      tapes: selectedTapeList || [],
      nas: selectedNasList || [],
      size: parseFloat(data.size.toString()),
      latitude: parseFloat(data.latitude.toString()),
      longitude: parseFloat(data.longitude.toString()),
      year: parseInt(data.year.toString()),
      code: data.code.toString(),
      type: data.type.toString(),
      rov: data.rov.toString(),
      title: data.title.toString(),
      businessUnit: data.businessUnit.toString(),
      location: data.location.toString(),
      vessel: data.vessel.toString(),
      contractLink: data.contract.toString(),
      folder: data.folder.toString(),
    };
    CreateItem("projects", project)
      .then(() => {
        setShowSuccessToast(true);
        setShowModal(false);
      })
      .catch((error) => {
        setShowFailedToast(true);
      });
  };
  return (
    <div
      id="crud-modal"
      tabIndex={-1}
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full max-w-full p-4 bg-gray-900 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50"
    >
      <div className="relative p-4 w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-w-2xl max-h-full">
        <div className="relative bg-white rounded shadow dark:bg-gray-700">
          <div className="flex items-center justify-be=tween p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Create New Project
            </h3>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="crud-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5">
            <div className="grid gap-4 mb-4 grid-cols-2">
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Project Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="name"
                  className=" border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type project name"
                  required
                />
              </div>
              <div className="col-span-1">
                <FormItems
                  name="code"
                  label="Finance Code"
                  placeholder="123456"
                />
              </div>
              <div className="col-span-1">
                <FormItems name="type" label="Type" placeholder="GVI" />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <FormItems
                  name="businessUnit"
                  label="Business Unit"
                  placeholder="Rovco"
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <FormItems name="rov" label="ROV" placeholder="ROV" />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <FormItems
                  name="latitude"
                  label="Latitude"
                  placeholder="1.0000"
                  type="number"
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <FormItems
                  name="longitude"
                  label="Longitude"
                  placeholder="1.0000"
                  type="number"
                />
              </div>
              <div className="col-span-2">
                <FormItems
                  name="location"
                  label="Location"
                  placeholder="Location"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="clients"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Client
                </label>
                <NewProjectSelect
                  type="clients"
                  setSelectedList={setSelectedClientList}
                  selectedList={selectedClientList ?? []}
                  setList={setClientList}
                  List={clientList ?? []}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="endClient"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  End Client
                </label>
                <NewProjectSelect
                  type="endClient"
                  setSelectedList={setSelectedEndClientList}
                  selectedList={selectedEndClientList ?? []}
                  setList={setClientList}
                  List={clientList ?? []}
                />
              </div>

              <div className="col-span-2 sm:col-span-1">
                <FormItems name="vessel" label="Vessel" placeholder="Vessel" />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="sensors"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sensor
                </label>
                <NewProjectSelect
                  type="sensors"
                  setSelectedList={setSelectedSensorList}
                  selectedList={selectedSensorList ?? []}
                  setList={setSensorList}
                  List={sensorList ?? []}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <FormItems name="size" label="Size" placeholder="1000" />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="year"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Year
                </label>
                <input
                  className=" border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  type="number"
                  min="2000"
                  max="2099"
                  step="1"
                  placeholder="2016"
                  name="year"
                  id="year"
                />
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="ip-rights"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Intellectual Property Rights
                </label>
                <Select
                  isMulti
                  id="sensor"
                  onChange={(e) =>
                    setSelectedIPList(
                      e.map((element) => ({ ID: element.value }))
                    )
                  }
                  classNames={{
                    control: () =>
                      " border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-0.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
                  }}
                  options={ipRightsList?.map((ip) => ({
                    value: ip.ID,
                    label: ip.name,
                  }))}
                />
              </div>
              <div className="col-span-2">
                <FormItems
                  name="contract"
                  label="Contract Link"
                  placeholder="https://rovco.sharepoint.com/sites/RovcoGeneral/contract.pdf"
                />
              </div>
              <div className="col-span-2">
                <FormItems
                  name="folder"
                  label="Sharepoint Project Folder"
                  placeholder="https://rovco.sharepoint.com/sites/RovcoGeneral/"
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="sensor"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  NAS Location
                </label>
                <NewProjectSelect
                  type="nas"
                  setSelectedList={setSelectedNasList}
                  selectedList={selectedNasList ?? []}
                  setList={setNasList}
                  List={nasList ?? []}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="tapes"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Tapes
                </label>
                <CreatableSelect
                  name="tapes"
                  id="tapes"
                  isMulti
                  onChange={(e) => {
                    setSelectedTapeList(
                      e.map((element) => ({ ID: element.value }))
                    );
                    setTapeOptions(
                      e.map((element) => ({
                        value: element.value,
                        label: element.label,
                      }))
                    );
                  }}
                  onCreateOption={(e) => {
                    setTapeCode(e);
                    setShowTapeModal(true);
                  }}
                  value={tapeOptions}
                  classNames={{
                    control: () =>
                      " border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-0.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
                  }}
                  options={tapeList?.map((el) => ({
                    value: el.ID,
                    label: el.code ?? "",
                  }))}
                />
              </div>
            </div>

            <button
              type="submit"
              onClick={(e) => postProject(e, session)}
              className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                className="me-1 -ms-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Add new project
            </button>
          </form>
        </div>
      </div>
      {showTapeModal && tapeList ? (
        <CreateTapeModal
          setShowTapeModal={setShowTapeModal}
          setTapeCode={setTapeCode}
          tapeCode={tapeCode}
          setTapeList={setTapeList}
          tapeList={tapeList}
        />
      ) : null}
      {showFailedToast ? (
        <FailedToast setShowFailedToast={setShowFailedToast} />
      ) : null}
      {showSuccessToast ? (
        <SuccessToast setShowSuccessToast={setShowSuccessToast} />
      ) : null}
    </div>
  );
};

export default NewProjectModal;
