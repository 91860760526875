type MapProps = {
    longitude: number;
    latitude: number;
}
const Map : React.FC<MapProps> = ({longitude,latitude}) => {
  return (
    <div className="flex flex-row space-between items-center p-1 bg-white border border-gray-200  ">
      <img
        src={`https://api.mapbox.com/styles/v1/mapbox/light-v11/static/geojson(%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${longitude}%2C${latitude}%5D%7D)/${longitude},${latitude},3/800x600?access_token=pk.eyJ1IjoidmFhcnN0IiwiYSI6ImNrdWZmbXQ2djE1NHQycnJ2dWx3YjdncTQifQ.a-W4AQUbrHVBTTE1S4eIcg`}
      />
    </div>
  );
};

export default Map;
