import { GeneralButton } from "../components/GeneralButton";


export function NotFound() {
  return (
    <div className="grid h-screen place-content-center bg-white px-4 w-full">
      <div className="text-center">
        <h1 className="text-9xl font-black text-gray-200">404</h1>

        <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Uh-oh!
        </p>

        <p className="mt-4 text-gray-500">We can't find that page.</p>

        <GeneralButton href="/" css="m-4">
            Go Back Home
        </GeneralButton>
      </div>
    </div>
  );
}
