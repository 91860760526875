import { Link } from "react-router-dom";

function SbListItem(props: { href: string; text: string; }) {
  return (
    <li>
      <Link
        to={props.href}
        className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-orange-vaarst"
      >
        <span className="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400">
          <i className="bx bx-home"></i>
        </span>
        <span className="text-sm font-medium">{props.text}</span>
      </Link>
    </li>

  );
}

export { SbListItem };