import React, { useEffect, useState } from "react";
// COmponents
import "./App.css";
import { Sidebar } from "./components/sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { Projects } from "./pages/Projects";
import { ProjectDetail } from "./pages/ProjectDetail";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { NotFound } from "./pages/NotFound";
import { RedirectPage } from "./pages/LoginLoadingPage";
// AWS Amplify Packages
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import {
  signInWithRedirect,
  signOut,
  getCurrentUser,
  AuthUser,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: "eu-west-2_LdNj8U324",
      userPoolClientId: "5u3mu4p1bj77fbe72q6l576dlq",
      signUpVerificationMethod: "code", 
      loginWith: {

        oauth: {
          domain: "vaarst.auth.eu-west-2.amazoncognito.com",
          scopes: ["email", "openid", "profile"],
          redirectSignIn: [process.env.REACT_APP_HOST_URL || ""],
          redirectSignOut: [process.env.REACT_APP_HOST_URL + "/logout"],
          responseType: "code", 
        },
      },
    },
  },
});


function App() {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [customState, setCustomState] = useState<string | null>(null);
  const getUser = async (): Promise<void> => {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (error) {
      console.error(error);

      console.log("Not signed in");
    }
  };

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
          getUser();

          break;

        case "signInWithRedirect_failure":
          setError("An error has ocurred during the OAuth flow.");

          break;

        case "customOAuthState":
          setCustomState(payload.data); // this is the customState provided on signInWithRedirect function

          break;
      }
    });

    getUser();

    return unsubscribe;
  }, []);

  
  if (user === null) {
    return (
      <div className="min-h-screen flex flex-row min-w-full">
        <Login />        
      </div>
    );
  } else {
    return (
      <Router>
        <div className="min-h-screen flex flex-row h-full min-w-full">
          <Routes>
            <Route path="/dashboard" element={
              <>
              <Sidebar/>
              <Dashboard />
              </>
            }>
            </Route>
            <Route path="/login" element={<Login/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/projects/:id" element={
              <>
              <Sidebar/>
              <ProjectDetail />
              </>
            }>
            </Route>
            <Route path="/" element={
              <>
              <Sidebar />
              <Projects />
              </>
            }>
            </Route>
            <Route path="/loading" element={<RedirectPage/>}/>
           
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
