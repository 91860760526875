type FormItemsProps = {
  name: string;
  label: string;
  placeholder: string;
  type?: string;
};
const FormItems: React.FC<FormItemsProps> = ({
  label,
  name,
  placeholder,
  type = "text",
}) => {
    return (
      <>
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
        </label>
        <input
          type={type}
          name={name}
          id={name}
          className=" border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder={placeholder}
          required
          step={type === "number" ? "0.0001" : undefined}
        />
      </>
    );
};

export default FormItems;
