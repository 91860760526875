import { Tape } from "../models/Tape";

type TapesCardProps = {
  tapes: Tape[];
};
const TapesCard: React.FC<TapesCardProps> = ({ tapes }) => {
  return (
    <div className="flex flex-col  w-full p-6 bg-white border border-gray-200 mb-2 mr-2">
      <p className="font-normal text-gray-700 dark:text-gray-400">Tapes</p>
      {tapes.map((tape) => (
      <div className="flex flex-row space-between items-center">

                    <h5 className="text-3xl font-bold tracking-tight text-gray-900">
          {tape.code}
        </h5>



      
      </div>
    ))}
    </div>
  );
};

export default TapesCard;
