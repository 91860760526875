import { Project } from "../models/Project";
import { faShip, faDatabase, faCircleInfo, faFan } from "@fortawesome/free-solid-svg-icons";
import Card from "./Card";
type CardRowProps = {
  project?: Project;
};

export const CardRow: React.FC<CardRowProps> = ({ project }) => {
  return (
    <div className=" flex flex-row w-full">
      <Card projectAttribute={project?.vessel} faIcon={faShip} text="Vessel" />

      <Card projectAttribute={project?.type} faIcon={faCircleInfo} text="Type" />
      <Card projectAttribute={project?.size} faIcon={faDatabase} text="Size" />
      <Card projectAttribute={project?.rov} faIcon={faFan} text="ROV" />
    </div>
  );
};
