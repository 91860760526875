import { Link } from "react-router-dom";




export function GeneralButton (props: any) {

    return (
        <Link to={props.href}>
        <button className={"bg-rovco-blue text-zinc-100 px-4 py-2 hover:bg-orange-vaarst rounded-sb " + props.css}>
                           {props.children}
                          </button>
                          </Link>
    )
}