import logo from "../images/randv_favicon_narrow.png";
import { SbListItem } from "./sbListItem";
import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

function Sidebar() {
  type AmplifyAuthSession = Awaited<ReturnType<typeof fetchAuthSession>>;
  const [session, setSession] = useState<AmplifyAuthSession | null>(null);
  useEffect(() => {
    try {
      fetchAuthSession().then((session) => {
        if (session) {
          setSession(session);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);
  return (
    <div className="flex flex-col w-56 bg-white rounded-r-3xl overflow-hidden shadow-lg min-w-60">
      <div className="flex items-center justify-center h-20 shadow-md">
        <div>
          <img src={logo} className="p-4" alt="logo" />
        </div>
        <div className="items-center justify-center">
          <p className="text-orange-vaarst pr-2 te">Data Catalog</p>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-3">
    

    <div className="ml-2 relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
        <span className="font-medium text-gray-600 dark:text-gray-300">{session?.tokens?.idToken?.payload.email?.toString()[0].toUpperCase()}</span>
    </div>
    
    
        <div className="font-medium text-gray-400">
            <div>{session?.tokens?.idToken?.payload.email?.toString().split("@")[0]}</div>
        </div>
    </div>
      <ul className="flex flex-col py-4">
        <SbListItem href="/" text="Projects" />
        <SbListItem href="/dashboard" text="Dashboard" />
      </ul>
      <div className="absolute bottom-4">
        <ul>
          <li
            className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-orange-vaarst"
            onClick={() => handleSignOut()}
          >
            <span className="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400">
              <i className="bx bx-home"></i>
            </span>
            <span className="text-sm font-medium">Logout</span>
          </li>
        </ul>





      </div>
    </div>
  );
}

export { Sidebar };
