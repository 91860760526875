import { getCurrentUser } from "aws-amplify/auth";
import { get } from "http";
import React from "react";


export function Dashboard() {
  return (
    currentAuthenticatedUser(),

    <main className="flex-col">
            <div className="flex">
              <div className="p-8">
                <div className="py-4">
                  <h2 className="text-2xl text-orange-vaarst">Dashboard</h2>
                </div>
                <div>
                 Graphs and stuff                  
                </div>
              </div>
            </div>
          </main>
  );
}

async function currentAuthenticatedUser() {

  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);
  } catch (err) {
    console.log(err);
  }

}