type LinksCardProps = {
  contract: string;
};
const LinksCard: React.FC<LinksCardProps> = ({ contract }) => {
  return (
    <div className="flex flex-col  w-full p-6 bg-white border border-gray-200  mr-2">
      <p className="font-normal text-gray-700 dark:text-gray-400">Links</p>
      <div className="flex flex-row space-between items-center">
        <a
          href="{contract}"
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          Contract
        </a>
      </div>
    </div>
  );
};

export default LinksCard;
