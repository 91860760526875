import { useState } from "react";
import CreateItem from "../utils/Create";
import { Tape } from "../models/Tape";
const CreateTapeModal = ({
  setShowTapeModal,
  tapeCode,
  setTapeCode,
    setTapeList,
    tapeList
}: {
  setShowTapeModal: (show: boolean) => void;
  tapeCode: string;
  setTapeCode: (show: string) => void;
  setTapeList: (tape: Tape[]) => void;
  tapeList: Tape[];

}) => {
  const [tapeSerialNumber, setTapeSerialNumber] = useState<number>();
  const [tapeType, setTapeType] = useState<string>();
  return (
    <div
      id="tape-modal"
      tabIndex={-1}
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 justify-center items-center w-full md:inset-0 h-full max-h-full max-w-full p-4 bg-gray-900 bg-opacity-50 dark:bg-gray-900 dark:bg-opacity-50"
    >
      <div className="relative p-4 w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Create New Product
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="crud-modal"
              onClick={() => setShowTapeModal(false)}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5">
            <div className="grid gap-4 mb-4 grid-cols-2">
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Code
                </label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type product name"
                  value={tapeCode}
                  onChange={(e) => {
                    setTapeCode(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="col-span-1">
                <label
                  htmlFor="serialNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  SerialNumber
                </label>
                <input
                  type="number"
                  id="serialNumber"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter serial number"
                  onChange={(e) => {
                    setTapeSerialNumber(parseInt(e.target.value));
                  }}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="type"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Type
                </label>
                <input
                  id="type"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter tape type"
                  onChange={(e) => {
                    setTapeType(e.target.value);
                  }}
                />
              </div>
            </div>
            <button
                className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={(e) => {
                    e.preventDefault();

                    CreateItem("tapes", {
                        code: tapeCode,
                        serialNumber: tapeSerialNumber,
                        type: tapeType,
                    }).then((item) => {
                            setTapeList([...tapeList, item] as Tape[]);
                        setShowTapeModal(false);
                    });
                }}
            >
              <svg
                className="me-1 -ms-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Add new tape
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTapeModal;
