import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import vaarst_logo from "../images/randv_narrow.png";

export function RedirectPage() {
  const location = useLocation();
  const authCode = new URLSearchParams(location.search).get("code");

  const clientId = "5u3mu4p1bj77fbe72q6l576dlq";
  const redirectUri = process.env.REACT_APP_API_URL?.toString()

  const tokenEndpoint = "https://vaarst.auth.eu-west-2.amazoncognito.com/oauth2/token";

  

  if (authCode) {

    const data = {
      grant_type: "authorization_code",
      client_id: clientId,
      redirect_uri: redirectUri,
      code: authCode,
      code_verifier: "1234567890123456789012345678901234567890123456789012345678901234"
    };


    fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        ...data,
        redirect_uri: redirectUri || '', // Provide a default value if redirectUri is undefined
      }),
    })
      .then(response => response.json())
      .then(tokens => {
        // Access, ID, and refresh tokens are available in the 'tokens' object
        console.log(tokens);
      })
      .catch(error => console.error('Token exchange error:', error));
  }
  return (
    <div className="grid justify-items-center">
      <div className="object-center size-96">
      <img className="animate-bounce" src={vaarst_logo} alt="vaarst_logo" />
      </div>
    </div>
  );
}
