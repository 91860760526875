import { useState, useEffect } from "react";

import { ProjectTableFull } from "../components/ProjectTableFull";
import { ProjectTablePartial } from "../components/ProjectTablePartial";
import { Project } from "../models/Project";
import { Client } from "../models/Client";
import { fetchAuthSession } from "aws-amplify/auth";
import NewProjectModal from "./NewProjectModal";
import { Sensor } from "../models/Sensor";
import { Ipr } from "../models/IntellectualPropertyRight";
import { Nas } from "../models/Nas"
import { Tape } from "../models/Tape";

export function Projects() {
  const [projectList, setProjectList] = useState<Project[]>();
  const [clientList, setClientList] = useState<Client[]>();
  const [sensorList, setSensorList] = useState<Sensor[]>();
  const [ipRightsList, setIpRightsList] = useState<Ipr[]>();
  const [nasList, setNasList] = useState<Nas[]>();
  const [tapeList, setTapeList] = useState<Tape[]>();
  const [tableTypeFull, setTableTypeFull] = useState<boolean>(
    localStorage.getItem("tableTypeFull") === "true" ? true : false
  );
  type AmplifyAuthSession = Awaited<ReturnType<typeof fetchAuthSession>>;
  const [session, setSession] = useState<AmplifyAuthSession | null>(null);
  const [showModal, setShowModal] = useState(false);
  const fetchData = (sess: any, endpoint: string, stateFunction: any) => {
    var headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${sess.tokens.idToken}`);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow" as RequestRedirect,
    };
    fetch(process.env.REACT_APP_API_URL + "/api/v1/" + endpoint, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        stateFunction(result);
      })
      .catch((error) => console.log("error", error));
  };
  const handleTableTypeChange = () => {
    setTableTypeFull(!tableTypeFull);
    localStorage.setItem("tableTypeFull", JSON.stringify(!tableTypeFull));
  };
  useEffect(() => {
    try {
      fetchAuthSession().then((session) => {
        if (session) {
          setSession(session);
        }
        fetchData(session, "projects", setProjectList);
        fetchData(session, "clients", setClientList);
        fetchData(session, "sensors", setSensorList);
        fetchData(session, "ip-rights", setIpRightsList);
        fetchData(session, "nas", setNasList);
        fetchData(session, "tapes", setTapeList);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <main className="flex-col w-full h-full">
      <div className="flex">
        <div className="p-8 w-full">
          <div className="py-4 flex flex-row justify-between">
            <h2 className="text-2xl text-orange-vaarst">Projects</h2>
            <label
              className="inline-flex items-center cursor-pointer"
              onChange={handleTableTypeChange}
            >
              <span className="me-3 text-sm font-medium ">Partial</span>
              <input
                type="checkbox"
                value=""
                className="sr-only peer ml-4"
                checked={tableTypeFull}
                readOnly
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-orange-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-600"></div>
              <span className="ms-3 text-sm font-medium ">Full</span>
            </label>
          </div>

          <div>
            {tableTypeFull ? (
              <ProjectTableFull projectList={projectList} />
            ) : (
              <ProjectTablePartial projectList={projectList} />
            )}
          </div>
        </div>
      </div>
      <div className="absolute right-0 ...">
      {session?.tokens?.idToken?.payload["cognito:groups"]?.toString().includes("data-catalog-admin") === true ? (
        <button
          type="button"
          onClick={() => setShowModal(true)}
          id="addNewProject"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Add New Project
        </button>
    ) : null}
      </div>

      {showModal ? (
        <NewProjectModal
          setShowModal={setShowModal}
          clientList={clientList}
          setClientList={setClientList}
          sensorList={sensorList}
          setSensorList={setSensorList}
          ipRightsList={ipRightsList}
          setIpRightsList={setIpRightsList}
          nasList={nasList}
          setNasList={setNasList}
          tapeList={tapeList}
          session={session}
          setTapeList={setTapeList}
          setProjectList={setProjectList}
          projectList={projectList}
        />
      ) : null}
    </main>
  );
}
