import { Link } from "react-router-dom";


export function TableButton (props: any) {

    const id = props.projectId;

    return (
        <Link to={"/projects/"+id}>
        <button className="bg-rovco-blue text-zinc-100 px-4 py-2 hover:bg-orange-vaarst rounded-md w-32">
                           {props.children}
                          </button>
                          </Link>
    )
}