import { layer } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type LocationCardProps = {
  location: string;
  latitude: number;
  longitude: number;
};
const LocationCard: React.FC<LocationCardProps> = ({
  location,
  latitude,
  longitude,
}) => {
  return (
    <div className="flex flex-col mb-2  w-full  min-w-56 max-w-sm p-6 bg-white border border-gray-200 mr-2">
        <div className="flex flex-row pb-2">

      <div className="ml-2">
        <p className="font-normal text-gray-700 dark:text-gray-400">
          Longitude
        </p>
        <h5 className="text-3xl font-bold tracking-tight text-gray-900">
          {longitude}
        </h5>
      </div>
      <div className="ml-3">
        <p className="font-normal text-gray-700 dark:text-gray-400">Latitude</p>
        <h5 className="text-3xl font-bold tracking-tight text-gray-900">
          {latitude}
        </h5>
        </div>
      </div>
      <div className="ml-3">
        <p className="font-normal text-gray-700 dark:text-gray-400">Location</p>
        <h5 className="text-3xl font-bold tracking-tight text-gray-900">
          {location}
        </h5>
        </div>
    </div>
  );
};

export default LocationCard;
